<template>
  <b-container fluid id="main">
    <div class="add-client-button">
      <add-client-button/>
    </div>
    <b-row>
      <b-col>
        <b-table striped hover
                 :sort-by="loadSortBy()"
                 :sort-desc="loadSortDesc()"
                 :sort-null-last="!localStore.sortIsDesc"
                 @sort-changed="saveSort"
                 :busy="storeState.clients === null"
                 :items="storeState.clients === null ? []:Object.values(storeState.clients)"
                 :fields="fields">
          <template v-slot:cell(id)="client">
            <delete-client-button v-bind:client="client.item"/>
          </template>
          <template v-slot:cell(name)="client">
            <b-link @click="() => {routerPush('/client/' + client.item.id)}">{{ client.item.name }}</b-link>
          </template>
            <template v-slot:head(apiKeyCount)>
                # API keys <span style="font-size: 80%; font-weight: normal">(Message/Online/Assigned/Total)</span>
            </template>
            <template v-slot:head(keycloaks)>
                # Keycloak users <span style="font-size: 80%; font-weight: normal">(Message/Online/Total)</span>
            </template>
          <template v-slot:cell(keycloaks)="client">
            <div class="keycloaks">
              <div v-for="keycloak in Object.values(client.item.keycloaks)" :key="keycloak.id">
                <em>{{keycloak.name}}</em>: {{ keycloak.apiKeyMessageCount }}/{{ keycloak.apiKeyOnlineCount }}/{{ keycloak.apiKeyCount }}
              </div>
            </div>
          </template>
          <template v-slot:table-busy>
            <div class="text-center text-danger">
              <b-spinner class="align-middle"></b-spinner>
              <strong> Loading...</strong>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {formatDate, store} from "@/main";

import AddClientButton from "@/components/buttons/AddClientButton.vue";
import DeleteClientButton from "@/components/buttons/DeleteClientButton.vue";

const localStore = {
  sortIsDesc: false,
}

export default {
  name: "ClientList",
  components: {AddClientButton, DeleteClientButton},
  data() {
    return {
      storeState: store.state,
      localStore: localStore,
      fields: [
        {
          key: 'id',
          label: '',
          thStyle: {width: '49px'},
        },
        {
          key: 'name',
          label: 'Name',
          sortable: true,
        },
        {
          key: 'apiKeyCount',
          sortable: true,
          sortDirection: "desc",
          formatter: (apiKeyCount, key, client) => {
            return client.apiKeyMessageCount + '/' + client.apiKeyOnlineCount + '/' + client.apiKeyAssignedCount + '/' + apiKeyCount
          }
        },
        {
          key: 'keycloaks',
          sortable: true,
          sortDirection: "desc",
          sortByFormatted: true,
          formatter: (keycloaks) => {
            if (Object.values(keycloaks).length !== 0) {
              let total = 0
              Object.values(keycloaks).forEach(keycloak => {
                total += keycloak.apiKeyCount
              })
              return total
            }
            return null
          }
        },
        {
          key: 'defaultServerId',
          label: 'Server',
          sortable: true,
          formatter: serverId => {
            if (store.state.servers !== null) {
              const server = store.state.servers[serverId]
              return server ? server.url : ''
            }
          }
        },
        {
          key: 'creationTimestamp',
          label: 'Creation date',
          sortable: true,
          formatter: timestamp => {
            return formatDate(new Date(timestamp))
          }
        }
      ],
    }
  },
  methods: {
    routerPush: function (path) {
      this.$router.push(path)
    },
    saveSort: function (ctx) {
      this.localStore.sortIsDesc = ctx.sortDesc
      localStorage.setItem("clientsSort", ctx.sortBy + ',' + ctx.sortDesc)
    },
    loadSortBy: function() {
      const sort = localStorage.getItem("clientsSort")
      if (sort && sort.includes(',')) {
        return sort.split(',')[0]
      }
      return 'name'
    },
    loadSortDesc: function() {
      const sort = localStorage.getItem("clientsSort")
      if (sort && sort.includes(',')) {
        this.localStore.sortIsDesc = sort.split(',')[1] === 'true'
      } else {
        this.localStore.sortIsDesc = false
      }
      return this.localStore.sortIsDesc
    },
  },
}
</script>

<style scoped>
#main {
  padding-top: 15px;
  padding-bottom: 15px;
}
.add-client-button {
  text-align: right;
  margin-bottom: 15px;
}
.keycloaks {
  display: flex;
  min-height: 48px;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: stretch;
  margin-top: -12px;
  margin-bottom: -12px;
}
</style>